import React, { useEffect, useRef, useState,useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/Web23_Logo-Horizontal_White.svg';
import NavBar from './navBar';
import $ from 'jquery';

const Header = (props) => {
	
    const [query, setQuery] = useState('');
    const [headerHeight, setHeaderHeight] = useState(92);
    const [totalHeaderHeight, setTotalHeaderHeight] = useState(176);
    const [cartPageHeight, setCartPageHeight] = useState(70);
    const router = useHistory();
    const location = useLocation();
    const headerRef = useRef(null);
    const mainHeaderRef = useRef(null);
   
	/*const checkPath=()=>{
       let paths = location.pathname.split('/');
	   if(paths!=null && paths.length==2 && paths[1]==="resolver"){
		   setIsResolver(true);
	   }
	   else if(paths!=null && paths.length>3){
		   setIsResolver(false);
			let activePage= paths[2];
			if(paths[3]) setSubPath(`${paths[3]}`);
			if( activePage != "search" && activePage != "cart"&& activePage != "domain" && activePage != "profile" && activePage != "coupons") $('.e-app-body-wrapper').css("padding-top", `${totalHeaderHeight}px`);
			else if(activePage === "search" || activePage == "cart" ) $('.e-app-body-wrapper').css("padding-top", `${cartPageHeight}px`);
			else $('.e-app-body-wrapper').css("padding-top", `${headerHeight}px`);
			
			setTimeout(() => {
				setBodyPadding(activePage === "search" || activePage == "cart"? true : false);
			}, 500);
		}
    }*/

    useEffect(()=>{
        //checkPath();
    },[location.pathname]);

    const setBodyPadding=(isCartPage)=>{
        let header = headerRef.current;
        let mainHeader = mainHeaderRef.current;
        let height = header.getBoundingClientRect().height;
        let mainHeight = mainHeader.getBoundingClientRect().height;
        if(isCartPage){
            if(cartPageHeight!=height){
                setCartPageHeight(height-1);
                $('.e-app-body-wrapper').css("padding-top", `${height-1}px`);
            }
        }
        else if(height!=totalHeaderHeight && mainHeight!=height){
            setHeaderHeight(mainHeight-1);
            setTotalHeaderHeight(height-1);
            $('.e-app-body-wrapper').css("padding-top", `${height-1}px`);
        }
        else if(mainHeight===height){
            setHeaderHeight(mainHeight-1);
            $('.e-app-body-wrapper').css("padding-top", `${height-1}px`);
        }
    }


    return (
        <>
        <header className="e-header position-fixed" ref={headerRef}>
            <div className="container py-3" ref={mainHeaderRef}>
                <div className="row gx-lg-3 gx-sm-2 gx-1">
                    <div className="col-sm-3 col-4">
                        <div className="h-100 d-flex align-items-center">
                            <Link to="/">
                                <img src={Logo} alt="Web 23 Logo" className="e-header-logo"  />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-5 py-2 col-md-5 d-none d-md-block">
						<div className="w-100 h-100 d-flex align-items-center justify-content-end ">
							<div className="position-absolute topM909" style={{top:"0px"}}>
							
							</div>
						</div>
                    </div>
	            </div>
            </div>
            {
                <NavBar/>
                
            }
        </header> 
        </>
    )
}

export default Header;