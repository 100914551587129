/* Package Imports */
import React, { useState , useEffect} from 'react';
import { BrowserRouter,Switch, Route, Redirect,useHistory,useLocation} from 'react-router-dom';

import { Footer, Header } from './components/navigation';
import W2assetResolver from './container/domains/w2assetResolver';
let pageName=window.location.pathname; 
function App(props) {
  const router = useHistory();	
  return (
	<>
    	<BrowserRouter>
			<Header />
			<div className="e-app-body-wrapper">
				<Switch>
				<Route path="/" component={W2assetResolver} exact />
				</Switch>	  
			</div>
		</BrowserRouter>		 
	</>	
  );
}

export default App;
