import React from "react";
import { Link, Route,useHistory } from "react-router-dom";
import styles from '../../styles/components/NavBar.module.scss';
import Dashboard from '../../assets/images/navBarIcons/home.svg'
import Domains from '../../assets/images/navBarIcons/domains.svg';
import Reports from '../../assets/images/navBarIcons/reports.svg';
import Accounts from '../../assets/images/navBarIcons/socialMedia.svg';
import Template from '../../assets/images/navBarIcons/templates.svg';
class JComponent extends React.Component{

	constructor(props){
		super(props);
		
	}
	
	async downoadExt(e){
		e.preventDefault();
		window.open("https://chrome.google.com/webstore/detail/web23/pddgamncjimlcfcihdfmfkplfbkdpjec");
		
	}
	
	
	render(){
			  return (
				<div className={`container-fluid ${styles.e_nav_wrapper} px-0 py-0`}>
				  <div className="container">
					<div className={styles.e_nav_container}>
					  <div className={styles.e_nav_content + " d-flex flex-row py-0"}>
						<div className='d-flex flex-grow-1'>
						<span className="mt-1 mb-1 text-light rounded-pill px-4">
						{/*<i className="bi bi-info-circle"></i> Alpha Testing for Early Access */}
						</span>
						</div>
						<div className='d-flex '>
						
						<button className='btn btn-sm btn-warning  px-4' onClick={(e)=>this.downoadExt(e)}>
						<span><i className="bi bi-download"></i>&nbsp;&nbsp;<span>Download Chrome Extension</span></span>
						</button>
						</div>
						
					  </div>  
					</div>
				  </div>
				</div>
			  )
	}
}
export default JComponent;