import React, { useEffect, useState,useContext,createContext } from "react";
import { useHistory } from "react-router";
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const W2assetResolver = (props) => {
 
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [domain,setDomain]=useState("");
  const router = useHistory();

  useEffect(() => {
	
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  
  
  const containsSpecialChars=(str)=> {
	    
		const specialChars = /^[a-zA-Z0-9\.]+$/;
		return !specialChars.test(str);
  }
   
   /* search Domain */
   const searchByKey=(e)=>{
	   // in case of keypress , keyCode in case of keyDown
	   if(e.charCode===13){
		   searchDomain(e);
	   } 
	   
   }
   const searchDomain=async (e)=>{
	   e.preventDefault();
	   var tmpDomain=document.getElementById('domain').value.trim();
	   if(tmpDomain===null || tmpDomain===""){return;}
	   else if(containsSpecialChars(tmpDomain)){ 
		toast.error("Special characters are not allowed");
		return;
	   }
	    else if(tmpDomain.length<=2){ 
		toast.error("Domain name length must be atleast 3 characters");
		return;
	   }
	   fetchDomainDetails(tmpDomain);
	  
	}
   
   function fetchDomainDetails(domName){
		var data = "domainName="+domName;

		
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

		var urlencoded = new URLSearchParams();
		urlencoded.append("domainNames", domName);
		urlencoded.append("token", "jasdjhajshdkajhdsakjdhakjgweydhbd87qye98dihwqh92bewufb8u18chvwb891v8b871uvy819vyy");

		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: urlencoded,
		  redirect: 'follow'
		};

		fetch(process.env.REACT_APP_RESOLVER+"/resolver/domains/searchDomain", requestOptions)
		  .then(response => response.text())
		  .then(result => 
		  {
			console.log(result);
			let datatmp=result;
			let data=JSON.parse(datatmp);
			if(data!=undefined && data!="" && data.data!=null && data.data[0].siteAddress!=undefined ){
	if(data.data[0].status===1){
		if(data.data[0].siteAddress.indexOf("http://")===0 || data.data[0].siteAddress.indexOf("https://")===0){
		window.open(data.data[0].siteAddress);
		}
		else{
			if(data.data[0].siteAddress!=""){
				window.open("http://"+data.data[0].siteAddress);
			}
			else{
				toast.error("Domain has no url");
			}
		}
	}
	else if(data.data[0].status===2){
	toast.error("Web3 domain not subscribed");	
	}
	else {
		toast.error("Web3 domain not minted");
	}
	
}
else{
	toast.error("Web3 domain not minted");
}
		  })
		  .catch(error => {
			 console.log(error); 
			 toast.error("Something went wrong!");
		  });
				
		
		
			
	}
   
  return (
  
   	<div className="e-manage-domains-page container-fluid px-0 pt-4 pb-5">
      <div className="container mt-4 pt-4">
        <div className="row mt-4 pt-4">
          <div className="col-7 pt-4 pb-3 mx-auto text-center mt-4">
           <h2>Resolve Hedera Domains</h2>
Humanizing Internet, Safely.
			
          </div>
        </div>
		<div className="row mt-4 mb-4">
        <div className="col-md-10 mx-auto">
            <div className="small fw-light"></div>
			<div className="input-group border rounded-pill shadow p-3 mb-5 bg-white" style={{padding:"0.5rem"}}>
                <input autoComplete="off" className="form-control  border-0 w3searchBox" maxlength={62} placeholder="Resolve your .hbar domain without Chrome Extension" onKeyPress={(e)=>searchByKey(e)} id="domain" name="domain" />
				<span className="input-group-append px-2">
				
				</span>
                <span className="input-group-append">
                    <button className="btn btn-success border rounded-pill ms-n5  px-4" type="button" onClick={(e)=>searchDomain(e)}>
                        Go
                    </button>
                </span>
            </div>
		  </div>
		
		</div>
		</div>
		<ToastContainer />
	  
</div> 


	
  )
}

export default W2assetResolver;
